import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Preview = ({ entity, id, label, queryParams, children, previewService = { getList: async () => ({ data: [] }) } }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await previewService.getPreview({ entity, id, queryParams });
                setData(response.data); 
            } catch (err) {
                setError('Error al cargar los datos');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [entity, id, queryParams]);

    const handleMouseEnter = () => {
        setShowPreview(true);
    };

    const handleMouseLeave = () => {
        setShowPreview(false);
    };

    const handleClick = () => {
        history.push(`/${entity}/${id}`);
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <span
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
                style={{
                    cursor: 'pointer',
                    color: 'black',
                    borderBottom: showPreview ? '2px dotted grey' : 'none',
                }}>
                {loading ? 'Cargando...' : error ? 'Error' : data ? data[label] : 'Sin datos'}
            </span>

            {showPreview && data && (
                <div
                style={{
                    position: 'absolute',
                    top: '100%',
                    left: '0',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '10px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    width: '400px',
                    zIndex: 2,
                }}>
                    {children(data)}
                </div>
            )}
        </div>
    );
};

export default Preview;
