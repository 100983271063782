import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { FormControl } from '@mui/material';
import getPhoneNumberLocale from 'core/locales/getPhoneNumberLocale';
import 'react-phone-input-2/lib/material.css';
import './styles.css';

// TODO: REVIEW THIS DEPENDENCIES
const PhoneNumber = ({ setFormError = () => {}, onFieldChange = () => {}, ...props }) => {
    const [hasError, setHasError] = useState(props.initialError);
    const [value, setValue] = useState(undefined);

    const validatePhone = (value, dialCode) => {
        const countryDialCode = dialCode.length;
        if (value) {
            if (countryDialCode === 1 && /^(?:(?:\+|00)88|01)?\d{11}$/i.test(value)) {
                return undefined;
            }
            if (countryDialCode === 2 && /^(?:(?:\+|00)88|01)?\d{12}$/i.test(value)) {
                return undefined;
            }
            if (countryDialCode === 3 && /^(?:(?:\+|00)88|01)?\d{13}$/i.test(value)) {
                return undefined;
            }
            if (countryDialCode === 4 && /^(?:(?:\+|00)88|01)?\d{14}$/i.test(value)) {
                return undefined;
            }
            return 'Invalid phone number';
        }
    };
    useEffect(() => setHasError(props.initialError), [props.initialError]);
    return (
        <FormControl className={props.className}>
            <style>
                {`.react-tel-input .special-label {
          color: ${hasError ? '#f44336' : 'black'};
        }
        .react-tel-input .form-control:hover {
          border-color: ${hasError ? '#f44336' : 'black'};
          cursor: default;
        }
        .react-tel-input .form-control:focus {
          border-color: ${hasError ? '#f44336' : '#333'};
        }
        .react-tel-input .form-control {
          border: 1px solid ${hasError ? '#f44336' : '#cacaca'};
        }
        .MuiFormHelperText-root.Mui-error {
          color: #f44336;
        }
        .MuiFormHelperText-contained {
          margin: 2px 0 0 2px;
          margin-left: 14px;
          margin-right: 14px;
        }
        .MuiFormHelperText-root {
          font-size: 12px;
          font-style: italic;
          font-family: Open sans, sans-serif;
          font-weight: 400;
          line-height: 22px;
        }`}
            </style>
            <PhoneInput
                style={{ height: 52, ...props.style }}
                country={props.initialCountry || 'us'}
                placeholder=""
                value={props.input.value}
                onChange={(value, country) => {
                    setHasError((prevValue) => {
                        const retValue = validatePhone(value, country.dialCode);
                        setFormError(retValue);
                        return retValue;
                    });
                    onFieldChange(`+${value}`, country.countryCode);
                    props.input?.onChange(value ? `+${value}` : null, country.countryCode);
                }}
                localization={getPhoneNumberLocale()}
                specialLabel={props.label}
            />
            {hasError || (!value && props.meta.touched) ? <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">{props.errorMessage}</p> : null}
        </FormControl>
    );
};

export default PhoneNumber;

// ======= OLD CODE =======

// import React from 'react';
// import 'react-phone-input-2/lib/material.css';
// import './styles.css';
// import ActivePhoneNumber from './Active';
// import DisabledPhoneNumber from './Disabled';

// const PhoneNumber = ({
//   disabled = false,
//   initialCountry,
//   label,
//   onFieldChange,
//   ...props
// }) => {
//   const render = () => {
//     const initialValue = props.input.value;
//     if (disabled) {
//       return (
//         <DisabledPhoneNumber
//           value={initialValue}
//           initialCountry={initialCountry}
//           label={label}
//         />
//       );
//     }
//     return (
//       <ActivePhoneNumber
//         value={initialValue}
//         initialCountry={initialCountry}
//         label={label}
//         onFieldChange={onFieldChange}
//       />
//     );
//   };

//   return <>{render()}</>;
// };

// export default PhoneNumber;
