import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Grid2, Box, Paper } from '@mui/material';
import { MoneyFormattedUtils } from '../../../../utils/moneyFormatted';
import { useTranslation } from 'core/contexts/localization';
import useStyles from './styles';
import { TimeDurationFormatter } from '../../../../utils/dateTimeFormatted';

const Slots = ({
    slot,
    quantity,
    daySelected,
    experience,
    setSelectedSlot,
    ...props
}) => {
    const { t: translate, i18n } = useTranslation();
    const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
    const classes = useStyles();

    React.useEffect(() => {
        setSelectedSlotIndex(null);
    }, [experience, daySelected, quantity]);

    if (!slot || !Array.isArray(slot)) {
        return '';
    }
    const selectedSlots =
        slot.filter((slot) => daySelected === slot.start?.substring(0, 10)) ||
        [];
    const handleSelecteSlot = async ({ index, item }) => {
        if (!item.available) {
            return;
        }
        setSelectedSlotIndex(index);
        setSelectedSlot({
            ...item,
            priceTotal: item.price * quantity,
            // timeOfArrivalFormatted: `${daySelected}T${item.time}.000Z`,
            timeOfArrivalFormatted: item.startUTC,
            timezone: item.timezone,
        });
    };

    return (
        <>
            <Grid2 container className={classes.gridFather} wrap="wrap">
                {selectedSlots.map((item, index) => (
                    <Grid2
                        key={item.id || index}
                        className={classes.slotGrid}
                        size={12}>
                        <Box
                            className={classes.slotContainer}
                            sx={{ m: 1 }}
                            textAlign="center">
                            <Paper
                                key={item.id || index}
                                data-testid={`slot-${item.id || index}`}
                                className={
                                    index === selectedSlotIndex
                                        ? `${classes.selectedPaper} ${classes.paperItem}`
                                        : item.available
                                        ? `${classes.availablePaper} ${classes.paperItem}`
                                        : `${classes.disabledPaper} ${classes.paperItem}`
                                }
                                onClick={() =>
                                    handleSelecteSlot({ index, item })
                                }>
                                {DateTime.fromISO(item.startUTC)
                                    .setZone(item.timezone)
                                    .toFormat('HH:mm')}
                                {` (${TimeDurationFormatter(
                                    item.duration,
                                    translate
                                )}) `}
                                {item.price ? (
                                    <>
                                        {index !== selectedSlotIndex &&
                                            item.available &&
                                            `${item.currency} `}
                                        {index === selectedSlotIndex
                                            ? translate(
                                                  'slot.selected'
                                              ).toUpperCase()
                                            : item.available
                                            ? MoneyFormattedUtils(
                                                  item.price,
                                                  i18n.language
                                              )
                                            : translate(
                                                  'slot.notAvailable'
                                              )}{' '}
                                        per person
                                    </>
                                ) : (
                                    'FREE'
                                )}
                            </Paper>
                        </Box>
                    </Grid2>
                ))}
            </Grid2>
        </>
    );
};

export default Slots;
