import makeStyles from '@mui/styles/makeStyles';
import palette from 'core/libs/core-ui/theme/colors-palette';

export const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '240px',
        maxWidth: '360px',
        marginBottom: '1rem',
        boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
        borderRadius: '1rem',
        '&:hover': {
            '& $cardHeader': {
                '& $img': {
                    transform: 'scale(1.15)',
                    transition: 'transform 0.3s',
                },
            },
            boxShadow:
                'rgba(0, 0, 0, 0.1) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            transform: 'scale(1.02)',
            transition: 'transform 0.3s',
        },
    },
    cardHeader: {
        overflow: 'hidden',
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overLayText: {
        fontWeight: 'bold',
        marginRight: 'auto',
        display: 'flex',
        marginTop: 'auto',
        color: 'white',
        fontSize: '1.5rem',
        padding: '0.5rem 1rem',
    },
    cardContent: {
        minHeight: 200,
    },
    priceBadge: {
        marginBottom: '0.2rem',
    },
    locationIcon: {
        marginRight: theme.spacing(1),
    },
    priceWrapRed: {
        textDecoration: 'line-through',
        backgroundColor: palette.utriper.orange,
        width: 'fit-content',
        padding: '4px 8px 2px 8px',
        fontWeight: 'bold',
        color: 'white',
        borderRadius: '0.5rem',
    },
    priceWrapGreen: {
        backgroundColor: palette.utriper.green,
        width: 'fit-content',
        padding: '4px 8px 2px 8px',
        color: 'white',
        borderRadius: '0.5rem',
        marginBottom: '0.5rem',
    },
    priceContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
    },
    buttonWrap: {
        width: '100%',
        margin: '0.4rem',
        backgroundColor: palette.utriper.lightblue,
        color: 'white',
        '&:hover': {
            backgroundColor: palette.utriper.darkblue,
        },
    },
    moreChips: {
        display: 'inline-block',
        marginLeft: '8px',
    },
    buttonBook: {
        fontWeight: 'bold',
        color: 'white',
    },
    modusesWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    modusChip: {
        margin: '0.3rem',
        fontSize: '0.7rem',
    },
    modusChipSmall: {
        fontSize: '0.2rem',
    },
    checkIcon: {
        marginTop: '0.2rem',
        marginRight: '0.3rem',
    },
    fromPrice: {
        fontWeight: 'bold',
        fontSize: '5rem',
    },
}));
