import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Button } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

import { useStyles } from './CopyLinkButton.styles';

const useCopyToClipboard = () => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = async (content) => {
        try {
            if (content === undefined || content === null) {
                throw new Error('There is no content to copy');
            }

            await navigator.clipboard.writeText(content);
            setIsCopied(true);
        } catch (error) {
            setIsCopied(false);
            console.error('Unable to copy to clipboard:', error);
        }
    };

    return { isCopied, copyToClipboard };
};

const CopyLinkButton = ({
    experienceId,
    experienceName,
    contractCode,
    label = 'Share',
    message = 'Copied to clipboard'
}) => {
    const classes = useStyles();
    const { isCopied, copyToClipboard } = useCopyToClipboard();

    if (!experienceId) {
        console.error('experience id is not valid.');
        return null;
    }

    const getExperienceLink = (id, code) => {
        const currentUrl = window.location.origin;

        let newUrl = `${currentUrl}/experience?id=${id}`;
        if (code) {
            newUrl += `&code=${code}`;
        }

        return newUrl;
    };

    const handleCopyButton = async (id, name, code) => {
        const url = getExperienceLink(id, code);

        await copyToClipboard(url);

        const msg = `${message}: ${name ?? ''}`;
        toast.info(msg, { position: 'top-right', theme: 'colored' });
    };

    return (
        <Button
            size="small"
            startIcon={<ShareIcon />}
            className={classes.shareButton}
            onClick={() => handleCopyButton(experienceId, experienceName, contractCode)}
        >
            {label}
        </Button>
    );
};

export default CopyLinkButton;
