import React from 'react';
import Preview from '../..';

const PreviewExperience = ({ id, previewService, label }) => {
    return (
        <Preview entity="experience" id={id} label={label} queryParams={{ name: 'name', description1: 'description1', pictureURL: 'pictureURL', experiencerName: 'experiencerName' }} previewService={previewService}>
            {(data) => (
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    {data.pictureURL && (
                        <div style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
                            <img
                                src={`${process.env.REACT_APP_CDN_URL}${data.pictureURL}`}
                                alt="Preview"
                                style={{
                                    width: '200px',
                                    height: 'auto',
                                    maxHeight: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '4px',
                                }}D
                            />
                        </div>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
                        <h1 style={{ color: '#333', margin: '0 0 5px 0', fontSize: '1.5rem', fontWeight: 'bold' }}>{data.name}</h1>
                        <h4 style={{ color: '#555', margin: '0 0 8px 0', fontSize: '1rem', fontWeight: 'normal' }}>
                            T.O: <strong>{data.experiencerName}</strong>
                        </h4>
                        <p style={{ color: '#777', margin: '0', fontSize: '0.9rem' }}>{data.description1}</p>
                    </div>
                </div>
            )}
        </Preview>
    );
};

export default PreviewExperience;
